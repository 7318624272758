import { Document } from '@contentful/rich-text-types';
import styled from '@emotion/styled';
import { useMemo, useRef, useState } from 'react';
import { breakpoints, colors, spacing } from '../../utils/styleguide';
import { colorsKurppa } from '../../utils/styleguideKurppa';
import ExpandableBox from '../component-library/expandableBox/ExpandableBox';
import Icon from '../component-library/Icon';
import { Col, Row } from '../component-library/layout/12ColGridLayout';
import Typography from '../component-library/text/Typography';
import { PageSectionComponent } from './pageSections';
import RichText from './RichText/RichText';

const Background = styled.div`
  background-color: ${colorsKurppa.grey01};
  padding: ${spacing[6]}px 0;
  ${breakpoints.desktop} {
    padding: ${spacing[10]}px 0;
  }
`;

const RowMain = styled(Row)`
  position: relative;
`;

const Tag = styled.div`
  height: 36px;
  padding: 4px 20px;
  border-radius: 40px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-right: ${spacing[1]}px;
  margin-bottom: ${spacing[1]}px;
  white-space: nowrap;
`;

const CategoryWrapper = styled.div`
  margin-top: ${spacing[6]}px;
  display: flex;
  overflow-y: scroll;

  ${breakpoints.tablet} {
    flex-wrap: wrap;
    overflow-y: auto;
  }
`;

const MoreArrow = styled.div<{ isScrollable: boolean }>`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  padding-top: 4px;
  right: 0;
  height: 40px;
  width: 50px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, ${colors.white} 50%);

  ${breakpoints.tablet} {
    display: none;
  }

  ${({ isScrollable }) => (isScrollable ? '' : 'display: none;')}
`;

const QuestionAndAnswer = styled(ExpandableBox)`
  margin-top: ${spacing[3]}px;

  :first-of-type {
    margin-top: 0;
  }
`;

const FooterWrapper = styled.div`
  margin-top: ${spacing[6]}px;

  ${breakpoints.tablet} {
    margin-top: ${spacing[10]}px;
  }
`;

function setFirstCharToUpperCase(str: string) {
  return `${str[0].toUpperCase()}${str.slice(1)}`;
}

const QuestionsAndAnswersSection: PageSectionComponent<null> = ({
  section: { fields: { buildingBlock: header, footer, references: questionsAndAnswers } = {} },
}) => {
  const [selectedCategory, setSelectedCategory] = useState<string>('all');

  const shouldShowCategoryBar = !!header?.fields.withCheckmarks;
  const categoryBarRef = useRef<HTMLDivElement>(null);
  // This value will only change on re-render and not when the width of the window changes. It is fine for this use-case,
  // but good to be aware of if we want to somehow expand it in the future
  const categoryBarIsScrollable =
    categoryBarRef.current &&
    categoryBarRef.current.scrollWidth > categoryBarRef.current.clientWidth;

  const categories = useMemo<string[]>(() => {
    if (!shouldShowCategoryBar || !questionsAndAnswers || questionsAndAnswers.length === 0) {
      return [];
    }
    const uniqueSortedCategories = questionsAndAnswers
      .flatMap((item) => item?.fields.categories as string)
      .map((item) => item.trim())
      .filter((value, index, self) => !!value && self.indexOf(value) === index)
      .sort();
    return ['all'].concat(uniqueSortedCategories);
  }, [questionsAndAnswers, shouldShowCategoryBar]);

  const filteredQuestionsAndAnswers = useMemo(() => {
    if (!questionsAndAnswers || questionsAndAnswers.length === 0) {
      return [];
    }
    if (!shouldShowCategoryBar || selectedCategory === 'all') {
      return questionsAndAnswers;
    }
    return questionsAndAnswers.filter((item) => {
      const categories = item?.fields.categories as string[];
      return categories.includes(selectedCategory);
    });
  }, [questionsAndAnswers, selectedCategory, shouldShowCategoryBar]);

  return (
    <Background>
      <RowMain>
        <Col tablet="3 / span 8" desktop="3 / span 8">
          {header && (
            <div>
              {header.fields.title && (
                <div>
                  <RichText document={header.fields.title} />
                </div>
              )}
              {header.fields.description && (
                <div css={{ marginTop: spacing[1] }}>
                  <RichText document={header.fields.description} />
                </div>
              )}
            </div>
          )}
          {categories && header?.fields.withCheckmarks && (
            <CategoryWrapper ref={categoryBarRef}>
              {categories.map((item) => (
                <Tag
                  role="button"
                  key={item}
                  onClick={() => {
                    let newlySelectedCategory = item;
                    if (selectedCategory === item) {
                      newlySelectedCategory = 'all';
                    }
                    setSelectedCategory(newlySelectedCategory);
                  }}
                  css={{
                    backgroundColor:
                      item === selectedCategory ? colorsKurppa.orange200 : colors.lightGray,
                  }}
                >
                  <Typography>{setFirstCharToUpperCase(item)}</Typography>
                </Tag>
              ))}
              {/* Right-margin hack since overflowing in y-direction doesn't allow for right-margin */}
              <div css={{ width: 20, color: colors.white }}>___</div>
              <MoreArrow
                isScrollable={categoryBarIsScrollable ?? true}
                onClick={() => {
                  if (categoryBarRef.current) {
                    categoryBarRef.current.scrollBy({ left: 400, behavior: 'smooth' });
                  }
                }}
              >
                <Icon name="chevron-right" size={36} css={{ marginRight: -10 }} />
              </MoreArrow>
            </CategoryWrapper>
          )}
          {filteredQuestionsAndAnswers && (
            <div
              css={{ marginTop: spacing[6] - spacing[1] }}
              itemScope
              itemType="https://schema.org/FAQPage"
            >
              {filteredQuestionsAndAnswers.map((item) => (
                <div
                  key={item?.fields.title as string}
                  itemProp="mainEntity"
                  itemScope
                  itemType="https://schema.org/Question"
                >
                  <QuestionAndAnswer
                    content={
                      <Typography variant="bodyBold" itemProp="name">
                        {item?.fields.title as string}
                      </Typography>
                    }
                    expandedContent={
                      <div itemProp="acceptedAnswer" itemScope itemType="https://schema.org/Answer">
                        <RichText
                          isFaq={true}
                          css={{ marginTop: spacing[3] }}
                          document={item?.fields.description as Document}
                        />
                      </div>
                    }
                    css={{ marginTop: spacing[3] }}
                  />
                </div>
              ))}
            </div>
          )}
          {footer && (
            <FooterWrapper css={{ marginTop: spacing[6] }}>
              {footer.fields.title && (
                <div>
                  <RichText document={footer.fields.title} />
                </div>
              )}
              {footer.fields.description && (
                <div css={{ marginTop: spacing[1] }}>
                  <RichText document={footer.fields.description} />
                </div>
              )}
            </FooterWrapper>
          )}
        </Col>
      </RowMain>
    </Background>
  );
};

export default QuestionsAndAnswersSection;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ReactNode, useState } from 'react';
import { colors, spacing } from '../../../utils/styleguide';
import IconButton from '../buttons/IconButton';

const MainWrapper = styled.div`
  background-color: ${colors.white};
  cursor: pointer;
`;

const ContentWrapper = styled.div`
  padding: ${spacing[3]}px ${spacing[4]}px;
  flex: 1;
`;

const ExpandedStyle = () => css`
  background-color: ${colors.orange100};
`;

const Expander = styled.div<{ isExpanded: boolean }>`
  padding: ${spacing[3]}px ${spacing[4]}px;
  align-self: stretch;
  ${({ isExpanded }) => (isExpanded ? ExpandedStyle : null)};
  display: flex;
  align-items: flex-end;
`;

interface ExpandableBoxProps {
  content: ReactNode;
  expandedContent: ReactNode;
  className?: string;
}

const ExpandableBox = ({ content, expandedContent, className }: ExpandableBoxProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <MainWrapper
      onClick={() => setIsExpanded((oldIsExpanded) => !oldIsExpanded)}
      role="button"
      className={className}
    >
      <div css={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
        <ContentWrapper>
          {content}
          <div css={{ display: isExpanded ? 'initial' : 'none' }}>{expandedContent}</div>
        </ContentWrapper>

        <Expander isExpanded={isExpanded}>
          <IconButton
            iconName={isExpanded ? 'chevron-s-up' : 'chevron-s-down'}
            variant="onlyIcon"
            ariaLabel="expand button"
          />
        </Expander>
      </div>
    </MainWrapper>
  );
};

export default ExpandableBox;
